import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ProductReviews, {
  ProductReviewsProps,
} from './components/ProductReviews';
import widgetDefinition from './ProductReviews.widget';
import { store } from './lib/redux/store';
import 'regenerator-runtime/runtime';
import { IntlProvider } from 'react-intl';
import normalizeWidgetInput from 'src/common/normalizeWidgetInput';
import { RenderFn } from 'src/types/widgets';
import { CarbonThemes } from 'src/types/carbon';
import { setTheme } from './lib/redux/slices/setThemeSlice';
import { setReviewsProvider } from './lib/redux/slices/setReviewsProviderSlice';
import { useProductReviewsDispatch } from './lib/redux/hooks';
import { ApiString } from './lib/redux/utils/selectApi';
import 'carbon-components/css/carbon-components.min.css';

// Add a wrapping component, so we can use hooks to dispatch the theme
// provided by the user input.
const ProductReviewsApp = ({
  useGoogleStars,
  productReviewsIds,
  palette,
  reviewsProvider,
}: ProductReviewsProps & {
  reviewsProvider: ApiString;
  palette: CarbonThemes;
}) => {
  const dispatch = useProductReviewsDispatch();
  dispatch(setTheme(palette));
  dispatch(setReviewsProvider(reviewsProvider));
  return (
    <ProductReviews
      useGoogleStars={useGoogleStars}
      productReviewsIds={productReviewsIds}
    />
  );
};

function refineInputTypes(input: Record<string, unknown>): ProductReviewsProps {
  // Convince TS that we have an array.
  const ids = input?.productReviewsIds || [];
  let productReviewsIds = Array.isArray(ids) ? ids : [];
  productReviewsIds = productReviewsIds.map((id) => `${id}`);
  const useGoogleStars =
    productReviewsIds.length === 1 && !!input.useGoogleStars;
  return { useGoogleStars, productReviewsIds };
}

const render: RenderFn = async function (instanceId, langCode, origin, cb) {
  const { element, locale, messages, palette, params } =
    await normalizeWidgetInput<ProductReviewsProps>(
      instanceId,
      langCode,
      widgetDefinition,
      refineInputTypes,
    );

  if (!element || !locale) {
    return;
  }

  const reviewsProvider = element?.getAttribute('data-reviews-provider');

  // Check the theme from input data. If it is not a valid theme, use the
  // palette from context.
  let theme = element.getAttribute('data-theme') || '';
  if (!Object.values<string>(CarbonThemes).includes(theme)) {
    theme = palette || CarbonThemes.WHITE;
  }

  ReactDOM.render(
    <React.StrictMode>
      <IntlProvider locale={locale} messages={messages}>
        <Provider store={store}>
          <ProductReviewsApp
            productReviewsIds={params.productReviewsIds}
            useGoogleStars={params.useGoogleStars}
            palette={theme as CarbonThemes}
            reviewsProvider={reviewsProvider as ApiString}
          />
        </Provider>
      </IntlProvider>
    </React.StrictMode>,
    element,
    () => cb(element),
  );
};

export default render;
