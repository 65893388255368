import { useGetG2ReviewsByIdsQuery } from '../slices/g2ReviewsApi';
import { useGetTrustRadiusReviewsByIdsQuery } from '../slices/trustRadiusReviewsApi';

export enum ApiString {
  TR = 'tr',
  G2 = 'g2',
}

export default function selectApi(apiString: ApiString) {
  switch (apiString) {
    case ApiString.G2:
      return useGetG2ReviewsByIdsQuery;
    case ApiString.TR:
    default:
      return useGetTrustRadiusReviewsByIdsQuery;
  }
}
