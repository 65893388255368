import React from 'react';
import StarRating from './StarRating';
import Truncate from './Truncate';
import parse from 'html-react-parser';
import { useIntl } from 'react-intl';
import { useProductReviewsSelector } from '../lib/redux/hooks';
import './CardBody.scss';

export interface CardBodyProps {
  /** Shown as the main text on the card. */
  text: string;
  /** Rating from 0 to 10. */
  rating: number;
  /** Date this review was written at. */
  createdDate?: string;
  /** Maximum number of lines before collapsing. */
  maxLines: number;
  reviewId: string;
  target: string;
}

const CardBody: React.FC<CardBodyProps> = ({
  text,
  rating,
  createdDate,
  maxLines,
  reviewId,
}) => {
  const { formatDate } = useIntl();
  const reviewsProvider = useProductReviewsSelector(
    (state) => state.reviewsProvider,
  );
  let created: Date | null = new Date(createdDate ?? '');
  if (created.toTimeString() === 'Invalid Date') {
    created = null;
  }
  return (
    <>
      <div>
        <StarRating
          starCount={5}
          value={reviewsProvider === 'tr' ? rating / 2.0 : rating}
          reviewId={reviewId}
        />

        <span className="product-reviews-widget__cardbody__dateline">
          {' '}
          {created
            ? formatDate(created, {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })
            : null}
        </span>
      </div>
      <div className="product-reviews-widget__cardbody__cardhr" />
      <div className="product-reviews-widget__cardbody__content">
        <Truncate lines={maxLines} ellipsis="..." trimWhitespace={false}>
          {parse(text)}
        </Truncate>
      </div>
    </>
  );
};

export default CardBody;
