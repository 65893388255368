import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { shuffle, uniq } from 'lodash';
import {
  ProductReviewsMetadata,
  ProductReviewsReview,
} from '../../../components/ProductReviews';

interface ProductInfo {
  _id: string;
  name: string;
  rating: { count: number; trScore: number };
  slug: string;
}

export type ProductData = {
  config: {
    products: ProductInfo[];
  };
  data: {
    company: { name: string; size: string; industry: { name: string } };
    quotes: {
      review: {
        _id: string;
        publishedDate: string;
        heading: string;
        slug: string;
      };
      text: string;
      rating: number;
      productName: string;
    }[];
    name: { first: string; last: string };
    position: { title: string };
  }[];
};

export default function normalizeProductData(product: ProductData): {
  metadata: ProductReviewsMetadata;
  reviews: ProductReviewsReview[];
} {
  const productInfo: ProductInfo = product?.config?.products[0];

  return {
    metadata: {
      productName: product.data[0].quotes[0].productName,
      slug: productInfo?.slug || '',
      totalCount: productInfo?.rating?.count || 1,
      rating: productInfo?.rating?.trScore || 10,
    },
    reviews: product?.data.map((item) => {
      return {
        reviewId: item.quotes[0].review._id,
        company: {
          name: item.company.name,
          size: item.company.size,
          industry: item.company.industry.name,
        },
        date: item.quotes[0].review.publishedDate,
        heading: item.quotes[0].review.heading,
        name: `${item.name.first} ${item.name.last}`,
        title: item.position.title,
        quotes: item.quotes.map((quote) => quote.text),
        rating: item.quotes[0].rating,
        slug: item.quotes[0].review.slug,
        linkToReview: `https://www.trustradius.com/reviews/${item.quotes[0].review.slug}`,
        productName: item.quotes[0].productName || '', // Used when rendering as multi-product widget.
      };
    }),
  };
}

export const trustRadiusReviewsApi = createApi({
  reducerPath: 'trustRadiusReviewsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.trustradius.com/api/v2/tqw/',
  }),
  endpoints: (builder) => ({
    getTrustRadiusReviewsByIds: builder.query({
      async queryFn(
        productReviewsIds: string[],
        _queryApi,
        _extraOptions,
        fetchWithBaseQuery,
      ) {
        const apiPromises = productReviewsIds.map(async (id) => {
          const { data, error } = await fetchWithBaseQuery(id);
          if (error) throw error;
          return data as ProductData;
        });

        const { data, error } = await Promise.all(apiPromises)
          .then((result) => {
            const normalizedProductData = result.map(normalizeProductData);
            const combinedProductData = normalizedProductData.reduce(
              (previous, current) => {
                return [...previous, ...current.reviews];
              },
              normalizedProductData[0].reviews,
            );
            return {
              data: {
                // We only make use of metadata when rendering a single-product widget.
                metadata: normalizedProductData[0].metadata,
                reviews: uniq(shuffle(combinedProductData)),
                singleProduct: normalizedProductData.length === 1,
              },
              error: undefined,
            };
          })
          .catch((error) => {
            return {
              data: undefined,
              error,
            };
          });

        return data ? { data } : { error };
      },
    }),
  }),
});

export const { useGetTrustRadiusReviewsByIdsQuery } = trustRadiusReviewsApi;
