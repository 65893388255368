import { CarbonBreakpoints } from 'src/types/carbon';

/**
 * Determine number of slides to display based on
 * total number of slides and the viewport width
 * @param {number} numberOfCards
 * @param {number} width
 * @param {boolean} isWithinTOC
 * @returns number
 */
export function determineSlidesDisplay(
  numberOfCards: number,
  width: number,
  isWithinTOC?: boolean,
) {
  switch (numberOfCards) {
    case 1:
      return 1;
    case 2:
      return width >= CarbonBreakpoints.MD ? 2 : 1;
    case 3:
      if (width >= CarbonBreakpoints.LG) {
        return 3;
      } else if (width >= CarbonBreakpoints.MD) {
        return 2;
      } else {
        return 1;
      }
    case 4:
    default:
      if (width >= CarbonBreakpoints.LG) {
        return isWithinTOC ? 3 : 4;
      } else if (width >= CarbonBreakpoints.MD) {
        return 2;
      } else {
        return 1;
      }
  }
}

export default determineSlidesDisplay;
