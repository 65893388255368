import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiString } from '../utils/selectApi';

const initialState = ApiString.TR;

const setReviewsProviderSlice = createSlice({
  name: 'reviewsProvider',
  initialState,
  reducers: {
    setReviewsProvider: (state, action: PayloadAction<ApiString>) =>
      (state = action.payload),
  },
});

export const { setReviewsProvider } = setReviewsProviderSlice.actions;

export default setReviewsProviderSlice.reducer;
