import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import DDSTextCTA from '@carbon/ibmdotcom-web-components/es/components-react/cta/text-cta.js';
import './AllReviewsButton.scss';
import messages from 'src/apps/product-reviews/locales/messages';

interface AllReviewsButtonProps {
  url: string;
  pageSize: number;
}

export const AllReviewsButton: React.FC<AllReviewsButtonProps> = ({
  url,
  pageSize,
}): ReactElement => (
  <DDSTextCTA
    href={url}
    target="_blank"
    ctaType="external"
    class="product-reviews-widget__text-cta"
    data-attribute1="ProductReviews-all"
  >
    {pageSize > 1 && <FormattedMessage {...messages.cardSliderReadAll} />}
  </DDSTextCTA>
);
