module.exports = {
  entry: './src/apps/product-reviews/index.tsx',
  shortcode: 'product-reviews',
  title: 'Product Reviews',
  status: 'stable',
  description:
    'Lists product reviews retrieved from specified product reviews service.',
  preview: {
    url: `${process.env.PUBLIC_URL}/storybook/iframe.html?id=widgets-product-reviews-components--default&viewMode=story`,
  },
  settingsSchema: {
    type: 'object',
    properties: {
      fields: {
        type: 'object',
        required: ['product-reviews-ids'],
        properties: {
          'reviews-provider': {
            type: 'string',
            title: 'Reviews Provider',
            enum: ['g2', 'tr'],
          },
          'product-reviews-ids': {
            type: 'array',
            title: 'Product IDs',
            description:
              'The ID found within the provider URL that activates the product reviews. Enter a single ID to display a single-product widget, or enter multiple IDs on separate lines to display a multi-product widget.',
            items: {
              type: 'string',
              pattern:
                '([0-9a-f]{24})|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})',
            },
            examples: [
              [
                '5e20addcac72e40024d9a00a',
                '61e97281a2b7200025596c0b',
                '61c342fe85d5d1004dd83a3d',
              ],
              ['5e20addcac72e40024d9a00a'],
            ],
          },
          theme: {
            type: 'string',
            title: 'Carbon Theme',
            description:
              "Force the palette of the widget to 'White', 'Gray 10', or 'Gray 100'. Otherwise the CMS should provide a value based on the page context.",
            default: 'null',
            examples: ['WHITE'],
            enum: ['null', 'WHITE', 'GRAY_10', 'GRAY_100'],
          },
          'google-stars': {
            type: 'boolean',
            title: 'Google Review Stars',
            description: `In single-product widgets, this enables Stars and review data on this page's Google search results.`,
            default: false,
          },
        },
      },
    },
  },
  useExternalPeerDependencies: ['react', 'react-dom', 'react-intl'],
  additionalCustomProperties: {
    webSegmentPaths: ['/cloud'],
    availableTranslations: [
      'de',
      'en',
      'es',
      'esla',
      'fr',
      'it',
      'ja',
      'ko',
      'pt',
      'zh-cn',
    ],
    uiFormSchema: {
      'reviews-provider': {
        'ui:widget': 'radios',
        'ui:help':
          'Select which reviews provider to use. The IDs entered into the field below should all be from the same provider. E.g. all G2 IDs or all Trust Radius IDs, etc.',
        'ui:enum': {
          labels: {
            mappings: {
              g2: 'G2',
              tr: 'Trust Radius',
            },
          },
        },
      },
      theme: {
        'ui:widget': 'select',
        'ui:enum': {
          labels: {
            mappings: {
              null: '-Derive value from page-',
              WHITE: 'White',
              GRAY_10: 'Gray 10',
              GRAY_100: 'Gray 100',
            },
          },
        },
      },
    },
  },
};
