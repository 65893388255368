import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { shuffle, uniq } from 'lodash';
import {
  ProductReviewsMetadata,
  ProductReviewsReview,
} from '../../../components/ProductReviews';

interface ProductInfo {
  id: string;
  name: string;
  review_count: number;
  avg_rating: number;
  slug: string;
}

type ProductData = {
  meta: {
    product: ProductInfo;
  };
  data: {
    attributes: {
      user: {
        company: string;
        company_segment: string;
        industry: string;
        name: string;
        title: string;
      };
      answers: {
        love: {
          value: string;
        };
      };
      star_rating: number;
      submitted_at: string;
      title: string;
      slug: string;
      url: string;
      product_name: string;
    };
    id: string;
  }[];
};

export default function normalizeProductData(product: ProductData): {
  metadata: ProductReviewsMetadata;
  reviews: ProductReviewsReview[];
} {
  return {
    metadata: {
      productName: product?.meta.product.name || '',
      slug: product?.meta.product.slug || '',
      totalCount: product?.meta.product.review_count || 1,
      rating: product?.meta.product.avg_rating || 10,
    },
    reviews: product?.data.map((item) => {
      return {
        reviewId: item.id,
        company: {
          name: item.attributes.user.company,
          size: item.attributes.user.company_segment,
          industry: item.attributes.user.industry,
        },
        date: item.attributes.submitted_at,
        heading: item.attributes.title, // title of review
        name: item.attributes.user.name,
        title: item.attributes.user.title, // reviewer's job title
        quotes: [item.attributes.answers.love.value],
        rating: item.attributes.star_rating,
        slug: item.attributes.slug,
        linkToReview: item.attributes.url,
        productName: item.attributes.product_name || '', // Used when rendering as multi-product widget.
      };
    }),
  };
}

const baseUrl = `https://api-proxy-prod-g2crowdsyndication.1kkdszd12iqm.us-east.codeengine.appdomain.cloud/api-proxy-prod-g2CrowdSyndication.json?query=${encodeURIComponent(
  '/reviews?filter[product_id]=',
)}`;

export const g2ReviewsApi = createApi({
  reducerPath: 'g2ReviewsApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  endpoints: (builder) => ({
    getG2ReviewsByIds: builder.query({
      async queryFn(
        productReviewsIds: string[],
        _queryApi,
        _extraOptions,
        fetchWithBaseQuery,
      ) {
        const apiPromises = productReviewsIds.map(async (id) => {
          const { data, error } = await fetchWithBaseQuery({
            url: baseUrl + id,
          });
          if (error) throw error;
          return data as ProductData;
        });

        const { data, error } = await Promise.all(apiPromises)
          .then((result) => {
            const normalizedProductData = result.map(normalizeProductData);
            const combinedProductData = normalizedProductData.reduce(
              (previous, current) => {
                return [...previous, ...current.reviews];
              },
              normalizedProductData[0].reviews,
            );
            return {
              data: {
                // We only make use of metadata when rendering a single-product widget.
                metadata: normalizedProductData[0].metadata,
                reviews: uniq(shuffle(combinedProductData)),
                singleProduct: normalizedProductData.length === 1,
              },
              error: undefined,
            };
          })
          .catch((error) => {
            return {
              data: undefined,
              error,
            };
          });

        return data ? { data } : { error };
      },
    }),
  }),
});

export const { useGetG2ReviewsByIdsQuery } = g2ReviewsApi;
