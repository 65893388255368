import { configureStore } from '@reduxjs/toolkit';
import { g2ReviewsApi } from './slices/g2ReviewsApi';
import { trustRadiusReviewsApi } from './slices/trustRadiusReviewsApi';
import setThemeSlice from './slices/setThemeSlice';
import setReviewsProviderSlice from './slices/setReviewsProviderSlice';

export const store = configureStore({
  reducer: {
    [g2ReviewsApi.reducerPath]: g2ReviewsApi.reducer,
    [trustRadiusReviewsApi.reducerPath]: trustRadiusReviewsApi.reducer,
    theme: setThemeSlice,
    reviewsProvider: setReviewsProviderSlice,
  },
  devTools: { name: 'Product Reviews' },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      g2ReviewsApi.middleware,
      trustRadiusReviewsApi.middleware,
    ),
});

export type ProductReviewsState = ReturnType<typeof store.getState>;
export type ProductReviewsDispatch = typeof store.dispatch;
