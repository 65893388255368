import React from 'react';
import { Star16, StarFilled16, StarHalf16 } from '@carbon/icons-react';

export type StarRatingProps = {
  starCount: number;
  value: number;
  reviewId: string;
};

const StarRating: React.FC<StarRatingProps> = ({
  starCount,
  value,
  reviewId,
}) => (
  <div
    className="dv-star-rating"
    style={{
      position: 'relative',
      boxSizing: 'border-box',
      display: 'inline-block',
    }}
  >
    {Array(starCount)
      .fill(0)
      .map((v, i) => i + 1)
      .map((position) => {
        const roundValue = Math.round(2 * value) / 2;
        // Render half a star if necessary.
        if (
          Math.floor(roundValue) < position &&
          Math.ceil(roundValue) === position
        ) {
          return (
            <React.Fragment key={`${reviewId}-half-star-wrapper`}>
              {/*
                The half-star icon lacks the full-star outline, which looks strange,
                so here we render both and then stack them onto one another.
              */}
              <Star16
                className="dv-star-rating-star dv-star-rating-empty-star"
                key={`${reviewId}-${position}`}
              />
              <StarHalf16
                className="dv-star-rating-star"
                key={`${reviewId}-${position}-half`}
                style={{
                  transform: 'translateX(-1rem) translateY(0rem)',
                  position: 'absolute',
                }}
              />
            </React.Fragment>
          );
        }
        return roundValue < position ? (
          <Star16
            className="dv-star-rating-star dv-star-rating-empty-star"
            key={`${reviewId}-${position}`}
          />
        ) : (
          <StarFilled16
            className="dv-star-rating-star dv-star-rating-full-star"
            key={`${reviewId}-${position}`}
          />
        );
      })}
  </div>
);

export default StarRating;
