import React, { ReactElement, useState, useEffect } from 'react';
import { useWindowSize } from 'src/common/hooks/useWindowSize';
import determineSlidesDisplay from '../lib/determineSlidesDisplay';
import SliderHeading from './SliderHeading';
import CardBody from './CardBody';
import { AllReviewsButton } from './AllReviewsButton';
import { FormattedMessage } from 'react-intl';
import selectApi from '../lib/redux/utils/selectApi';
import { useProductReviewsSelector } from '../lib/redux/hooks';
import { Row, Grid, Loading } from 'carbon-components-react';
import DDSCard from '@carbon/ibmdotcom-web-components/es/components-react/card/card';
import DDSCardFooter from '@carbon/ibmdotcom-web-components/es/components-react/card/card-footer';
import DDSCardHeading from '@carbon/ibmdotcom-web-components/es/components-react/card/card-heading';
import DDSCarousel from '@carbon/ibmdotcom-web-components/es/components-react/carousel/carousel';
import Googlestars from './Googlestars';
import './ProductReviews.scss';
import messages from 'src/apps/product-reviews/locales/messages';

export type HOF<T> = (input: T) => T;

export interface ProductReviewsProps {
  useGoogleStars: boolean;
  productReviewsIds: string[];
}

export interface ProductReviewsMetadata {
  productName: string;
  slug: string;
  totalCount: number;
  rating: number;
}

export interface ProductReviewsReview {
  reviewId: string;
  company?: {
    name: string;
    size?: string;
    industry?: string;
  };
  date: string;
  heading: string;
  name: string;
  title: string;
  quotes: string[];
  rating: number;
  slug: string;
  linkToReview: string;
  productName: string;
}

export const ProductReviews: React.FC<ProductReviewsProps> = ({
  useGoogleStars = false,
  productReviewsIds,
}) => {
  const reviewsProvider = useProductReviewsSelector(
    (state) => state.reviewsProvider,
  );
  const useGetReviewsByIdsQuery = selectApi(reviewsProvider);
  const { data, error, isLoading } = useGetReviewsByIdsQuery(productReviewsIds);
  const theme = useProductReviewsSelector((state) => state.theme);
  const [pageSize, setPageSize] = useState(4);
  const { width } = useWindowSize();
  const bodyElement = document.body.getAttribute('data-fullwidthtemplate');
  const isFullWidth = bodyElement;
  let isWithinTOC = false;
  if (isFullWidth === 'false') {
    isWithinTOC = true;
  } else {
    isWithinTOC = false;
  }

  useEffect(() => {
    const numberOfReviews = data ? data?.reviews.length - 1 : 4;
    const pageSize = determineSlidesDisplay(
      numberOfReviews,
      width,
      isWithinTOC,
    );
    setPageSize(pageSize);
  }, [width]);

  // Regardless of editor's google-stars selection, only render if this is a single-product instance.
  const starsComponent =
    data?.singleProduct && useGoogleStars ? (
      <Googlestars
        product={data.metadata.productName}
        count={data.metadata.totalCount}
        rating={data.metadata.rating}
      />
    ) : null;

  // We want this value if we're fetching only one product id.
  const reviewUrl = data?.singleProduct
    ? reviewsProvider === 'tr'
      ? `https://www.trustradius.com/products/${data?.metadata.slug}/reviews?rk=ibmcvs20181&utm_campaign=tqw&utm_medium=widget&utm_source=www.trustradius.com&trtid=36d1014e-506a-4f6f-950b-7b22b55ffdc6`
      : `https://www.g2.com/products/${data?.metadata.slug}/reviews`
    : reviewsProvider === 'tr'
    ? `https://www.trustradius.com/vendors/ibm`
    : `https://www.g2.com/search?utf8=%E2%9C%93&query=ibm`;
  const wrapComponent: HOF<ReactElement> = (component) => (
    <div
      className={`product-reviews-widget product-reviews-widget__${theme} widget-styles`}
    >
      <div className="product-reviews-widget__wrapper widget-reset">
        {component}
      </div>
    </div>
  );

  if (isLoading) {
    return wrapComponent(
      <div className="product-reviews-widget__message">
        <Loading withOverlay={false} />
      </div>,
    );
  }

  if (error) {
    return wrapComponent(
      <div className="product-reviews-widget__message">
        <FormattedMessage {...messages.errorMessage} />
      </div>,
    );
  }

  return wrapComponent(
    <Grid>
      <Row>
        {starsComponent}
        <SliderHeading reviewUrl={reviewUrl}>
          <span>
            {data?.singleProduct ? (
              <FormattedMessage
                {...messages.cardSliderHeading}
                values={{
                  name: data.metadata.productName,
                }}
              />
            ) : (
              <FormattedMessage {...messages.cardSliderHeadingAlt} />
            )}
          </span>
        </SliderHeading>
        <DDSCarousel pageSize={pageSize} data-attribute1="ProductReviews-arrow">
          {data?.reviews.map((review, i) => (
            <DDSCard
              key={`dds-card-${i}`}
              href={review.linkToReview}
              type="external"
            >
              <DDSCardHeading>
                {data.singleProduct ? review.heading : review.productName}
              </DDSCardHeading>
              <CardBody
                text={review.quotes
                  .map((quote: string) => `${quote}<br/><br/> `)
                  .join('')}
                rating={review.rating}
                createdDate={review.date}
                maxLines={7}
                reviewId={review.reviewId}
                target="_blank"
              />
              <DDSCardFooter target="_blank">
                <div className="product-reviews-widget__byline">
                  {review.name}
                  <br />
                  {review.title}
                  <br />
                  {review.company?.name}
                  <br />
                  {review.company?.industry} {review.company?.size}
                </div>
              </DDSCardFooter>
            </DDSCard>
          ))}
        </DDSCarousel>
        {reviewUrl && <AllReviewsButton url={reviewUrl} pageSize={pageSize} />}
      </Row>
    </Grid>,
  );
};

export default ProductReviews;
