import React from 'react';
import { useProductReviewsSelector } from '../lib/redux/hooks';
import { ReactComponent as TRLogo } from '../assets/images/trust-radius-logo.svg';
import { ReactComponent as G2Logo } from '../assets/images/g2-logo.svg';
import './SliderHeading.scss';

export interface SliderHeadingProps {
  /** The URL used to link to the review. */
  reviewUrl: string | undefined;
}

const SliderHeading: React.FC<SliderHeadingProps> = ({
  reviewUrl,
  children,
}) => {
  const reviewsProvider = useProductReviewsSelector(
    (state) => state.reviewsProvider,
  );
  const logoProps = {
    className: 'product-reviews-widget__sliderheading__headimage',
    alt: 'Product logo',
  };
  return (
    <a
      href={reviewUrl || ''}
      className="product-reviews-widget__sliderheading__cardlinks"
      target="_blank"
      data-attribute1="ProductReviews-logo"
    >
      <div className="product-reviews-widget__sliderheading__headlink">
        {children}
        {reviewsProvider === 'tr' ? (
          <TRLogo {...logoProps} />
        ) : (
          <G2Logo {...logoProps} />
        )}
      </div>
    </a>
  );
};

export default SliderHeading;
